<template>
	<div class="mainTem">
		<!--查看图片-->
		<!-- <el-dialog :title="$t('FormMsg.picture')" append-to-body :close-on-click-modal="false" :visible.sync="dialogImgVisible" width="1000px" top="0">
      <div style="overflow: auto; width: 100%; height: 80%;"><img :src="imgUrlBigShow" width="auto" height="auto" /></div>
      <div slot="footer" class="dialog-footer"><el-button type="primary" plain @click="dialogImgVisible = false">{{$t('FormMsg.Close')}}</el-button></div>
    </el-dialog> -->

		<!--选择入库数据-->
		<!-- <el-dialog :title="$t('FormMsg.Select')" append-to-body :close-on-click-modal="false" :visible.sync="dialogSelVisible" width="1200px" top="0">
      <div slot="footer" class="dialog-footer"><el-button type="primary" plain @click="dialogSelVisible = false">{{$t('FormMsg.Close')}}</el-button></div>
    </el-dialog> -->

		<!--查看明细-->
		<!-- <el-dialog :title="$t('FormMsg.Select')" append-to-body :close-on-click-modal="false" :visible.sync="dialogShowDet" width="1200px" top="0">
       <div slot="footer" class="dialog-footer"><el-button type="primary" plain @click="dialogShowDet = false">{{$t('FormMsg.Close')}}</el-button></div>
    </el-dialog> -->

		<!-- 导入excel -->
		<!-- <el-dialog :title="$t('Storage.DropShipping.Import')" append-to-body :close-on-click-modal="false" :visible.sync="dialogUploadVisible" width="1400px" top="0" v-loading="loadingExcel"> -->
		<!-- <div v-loading="loadingExcel">
			<input
				style="display: none;"
				type="file"
				id=""
				ref="file"
				@change="fileChange($event)"
				:accept="'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'"
			/>
			<el-button type="success" size="small" icon="el-icon-plus" @click="openExcel">{{ $t('module.upload.Select_Excel') }}</el-button>
			<span style="display: inline-block; width: 200px;">{{ excelFileName }}</span>
			<el-button type="primary" size="small" icon="el-icon-upload" @click="uploadExcel">{{ $t('module.upload.Upload_preview') }}</el-button>
			<span style="padding-left: 100px;">
				<el-link :href="'https://huyi-oss-wh.oss-cn-hangzhou.aliyuncs.com/newModel/bactchLabel.xlsx?random=' + Math.random()" target="_blank" type="primary" icon="el-icon-download">
					{{ $t('module.upload.Download_template') }}
				</el-link>
			</span>
		</div> -->
		
		<uploadExcelBtn
					:openTime="openTimeUpExc" 
					:readHttp="$urlConfig.WhFuerthertryReadExcel"
					:templateUrl="'https://huyi-oss-wh.oss-cn-hangzhou.aliyuncs.com/newModel/bactchLabel.xlsx'"
					@uploadSuccess="uploadSuccess">
					</uploadExcelBtn>
					
		<div style="overflow: auto; width: 100%;">
			<el-table :data="excelData" stripe :border="true" :max-height="$store.state.contenHeight - 250" style="width: 100%" size="small">
				
				<el-table-column type="index" fixed="left" width="50" align="center" :label="$t('Storage.tableColumn.no')"></el-table-column>
				

				
				
				<el-table-column prop="">
					<template :slot="'header'">
						<div>
							<span>{{$t('i18nn_b7367a5ccf9facf3')}}</span>-<span>{{$t('i18nn_ea4756bc1642e0f1')}}</span>1
						</div>
					</template>
					<template slot-scope="scope2">
						<div v-if="scope2.row.senderVo">
							<div>{{ scope2.row.senderVo.firstname }}</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="">
					<template :slot="'header'">
						<div>
							<span>{{$t('i18nn_b7367a5ccf9facf3')}}</span>-<span>{{$t('i18nn_ea4756bc1642e0f1')}}</span>2
						</div>
					</template>
					<template slot-scope="scope2">
						<div v-if="scope2.row.senderVo">
							<div>{{ scope2.row.senderVo.lastname }}</div>
						</div>
					</template></el-table-column>
				<el-table-column prop="">
					<template :slot="'header'">
						<div>
							<span>{{$t('i18nn_b7367a5ccf9facf3')}}</span>-<span>{{$t('i18nn_1df68c34677c2aae')}}</span>
						</div>
					</template>
					<template slot-scope="scope2">
						<div v-if="scope2.row.senderVo">
							<div>{{ scope2.row.senderVo.company }}</div>
						</div>
					</template></el-table-column>
				<el-table-column prop="">
					<template :slot="'header'">
						<div>
							<span>{{$t('i18nn_b7367a5ccf9facf3')}}</span>-<span>{{$t('i18nn_8758fd50c87d6c9c')}}</span>1
						</div>
					</template>
					<template slot-scope="scope2">
						<div v-if="scope2.row.senderVo">
							<div>{{ scope2.row.senderVo.addr1 }}</div>
						</div>
					</template></el-table-column>
				<el-table-column prop="">
					<template :slot="'header'">
						<div>
							<span>{{$t('i18nn_b7367a5ccf9facf3')}}</span>-<span>{{$t('i18nn_8758fd50c87d6c9c')}}</span>2
						</div>
					</template>
					<template slot-scope="scope2">
						<div v-if="scope2.row.senderVo">
							<div>{{ scope2.row.senderVo.addr2 }}</div>
						</div>
					</template></el-table-column>
				<el-table-column prop="">
					
					<template :slot="'header'">
						<div>
							<span>{{$t('i18nn_b7367a5ccf9facf3')}}</span>-<span>{{$t('i18nn_e05b5d049b64b040')}}</span>
						</div>
					</template>
					<template slot-scope="scope2">
						<div v-if="scope2.row.senderVo">
							<div>{{ scope2.row.senderVo.city }}</div>
						</div>
					</template></el-table-column>
				<el-table-column prop="">
					<template :slot="'header'">
						<div>
							<span>{{$t('i18nn_b7367a5ccf9facf3')}}</span>-<span>{{$t('i18nn_7cab79716236ebb0')}}</span>/<span>{{$t('i18nn_f9514183570a10fe')}}</span>(<span>{{$t('i18nn_8b90c4cfdf339af8')}}</span>)
						</div>
					</template>
					<template slot-scope="scope2">
						<div v-if="scope2.row.senderVo">
							<div>{{ scope2.row.senderVo.province }}</div>
						</div>
					</template></el-table-column>
				<el-table-column prop="">
				<template :slot="'header'">
					<div>
						<span>{{$t('i18nn_b7367a5ccf9facf3')}}</span>-<span>{{$t('i18nn_b166e4e8fe9513fa')}}</span>(US)
					</div>
				</template>
				<template slot-scope="scope2">
						<div v-if="scope2.row.senderVo">
							<div>{{ scope2.row.senderVo.country }}</div>
						</div>
					</template></el-table-column>
				<el-table-column prop="">
					<template :slot="'header'">
						<div>
							<span>{{$t('i18nn_b7367a5ccf9facf3')}}</span>-<span>{{$t('i18nn_c4913ab43009b365')}}</span>
						</div>
					</template>
					<template slot-scope="scope2">
						<div v-if="scope2.row.senderVo">
							<div>{{ scope2.row.senderVo.postcode }}</div>
						</div>
					</template></el-table-column>
				<el-table-column prop="">
					<template :slot="'header'">
						<div>
							<span>{{$t('i18nn_b7367a5ccf9facf3')}}</span>-<span>{{$t('i18nn_2d13b6f878f30be3')}}</span>
						</div>
					</template>
					<template slot-scope="scope2">
						<div v-if="scope2.row.senderVo">
							<div>{{ scope2.row.senderVo.mobile }}</div>
						</div>
					</template></el-table-column>
				<el-table-column prop="">
					<template :slot="'header'">
						<div>
							<span>{{$t('i18nn_b7367a5ccf9facf3')}}</span>-<span>mail</span>
						</div>
					</template>
					<template slot-scope="scope2">
						<div v-if="scope2.row.senderVo">
							<div>{{ scope2.row.senderVo.mail }}</div>
						</div>
					</template></el-table-column>
				
				<el-table-column prop="">
					<template :slot="'header'">
						<div>
							<span>{{$t('i18nn_ab3ce045a4241cb0')}}</span>-<span>{{$t('i18nn_ea4756bc1642e0f1')}}</span>1
						</div>
					</template>
					<template slot-scope="scope2">
						<div v-if="scope2.row.receiverVo">
							<div>{{ scope2.row.receiverVo.firstname }}</div>
						</div>
					</template></el-table-column>
				<el-table-column prop="">
					<template :slot="'header'">
						<div>
							<span>{{$t('i18nn_ab3ce045a4241cb0')}}</span>-<span>{{$t('i18nn_ea4756bc1642e0f1')}}</span>2
						</div>
					</template>
					<template slot-scope="scope2">
						<div v-if="scope2.row.receiverVo">
							<div>{{ scope2.row.receiverVo.lastname }}</div>
						</div>
					</template></el-table-column>
				<el-table-column prop="">
					<template :slot="'header'">
						<div>
							<span>{{$t('i18nn_ab3ce045a4241cb0')}}</span>-<span>{{$t('i18nn_1df68c34677c2aae')}}</span>
						</div>
					</template>
					<template slot-scope="scope2">
						<div v-if="scope2.row.receiverVo">
							<div>{{ scope2.row.receiverVo.company }}</div>
						</div>
					</template></el-table-column>
				<el-table-column prop="">
					<template :slot="'header'">
						<div>
							<span>{{$t('i18nn_ab3ce045a4241cb0')}}</span>-<span>{{$t('i18nn_8758fd50c87d6c9c')}}</span>1
						</div>
					</template>
					<template slot-scope="scope2">
						<div v-if="scope2.row.receiverVo">
							<div>{{ scope2.row.receiverVo.addr1 }}</div>
						</div>
					</template></el-table-column>
				<el-table-column prop="">
					<template :slot="'header'">
						<div>
							<span>{{$t('i18nn_ab3ce045a4241cb0')}}</span>-<span>{{$t('i18nn_8758fd50c87d6c9c')}}</span>2
						</div>
					</template>
					<template slot-scope="scope2">
						<div v-if="scope2.row.receiverVo">
							<div>{{ scope2.row.receiverVo.addr2 }}</div>
						</div>
					</template></el-table-column>
				<el-table-column prop="">
					<template :slot="'header'">
						<div>
							<span>{{$t('i18nn_ab3ce045a4241cb0')}}</span>-<span>{{$t('i18nn_e05b5d049b64b040')}}</span>
						</div>
					</template>
					<template slot-scope="scope2">
						<div v-if="scope2.row.receiverVo">
							<div>{{ scope2.row.receiverVo.city }}</div>
						</div>
					</template></el-table-column>
				<el-table-column prop="">
					<template :slot="'header'">
						<div>
							<span>{{$t('i18nn_ab3ce045a4241cb0')}}</span>-<span>{{$t('i18nn_7cab79716236ebb0')}}</span>/<span>{{$t('i18nn_f9514183570a10fe')}}</span>(<span>{{$t('i18nn_8b90c4cfdf339af8')}}</span>)</span>
						</div>
					</template>
					<template slot-scope="scope2">
						<div v-if="scope2.row.receiverVo">
							<div>{{ scope2.row.receiverVo.province }}</div>
						</div>
					</template></el-table-column>
				<el-table-column prop="">
					<template :slot="'header'">
						<div>
							<span>{{$t('i18nn_ab3ce045a4241cb0')}}</span>-<span>{{$t('i18nn_b166e4e8fe9513fa')}}</span>(US)
						</div>
					</template>
					<template slot-scope="scope2">
						<div v-if="scope2.row.receiverVo">
							<div>{{ scope2.row.receiverVo.country }}</div>
						</div>
					</template></el-table-column>
				<el-table-column prop="">
					<template :slot="'header'">
						<div>
							<span>{{$t('i18nn_ab3ce045a4241cb0')}}</span>-<span>{{$t('i18nn_c4913ab43009b365')}}</span>
						</div>
					</template>
					<template slot-scope="scope2">
						<div v-if="scope2.row.receiverVo">
							<div>{{ scope2.row.receiverVo.postcode }}</div>
						</div>
					</template></el-table-column>
				<el-table-column prop="">
					<template :slot="'header'">
						<div>
							<span>{{$t('i18nn_ab3ce045a4241cb0')}}</span>-<span>{{$t('i18nn_2d13b6f878f30be3')}}</span>(<span>{{$t('i18nn_edb1b11e6c5ceb01')}}</span>)
						</div>
					</template>
					<template slot-scope="scope2">
						<div v-if="scope2.row.receiverVo">
							<div>{{ scope2.row.receiverVo.mobile }}</div>
						</div>
					</template></el-table-column>
				<el-table-column prop="" >
					<template :slot="'header'">
						<div>
							<span>{{$t('i18nn_ab3ce045a4241cb0')}}</span>-<span>mail</span>
						</div>
					</template>
					<template slot-scope="scope2">
						<div v-if="scope2.row.receiverVo">
							<div>{{ scope2.row.receiverVo.mail }}</div>
						</div>
					</template></el-table-column>
				
				<el-table-column prop="" :label="$t('i18nn_b6bf0a07fe26f74f')"><template slot-scope="scope2">
						<div v-if="scope2.row.packageVo">
							<div>{{ scope2.row.packageVo.sizeUnitName }}</div>
						</div>
					</template></el-table-column>
				<el-table-column prop="" :label="$t('i18nn_0e60303b30d5d4b4')"><template slot-scope="scope2">
						<div v-if="scope2.row.packageVo">
							<div>{{ scope2.row.packageVo.length }}</div>
						</div>
					</template></el-table-column>
				<el-table-column prop="" :label="$t('i18nn_6cd31871f8528dd5')"><template slot-scope="scope2">
						<div v-if="scope2.row.packageVo">
							<div>{{ scope2.row.packageVo.width }}</div>
						</div>
					</template></el-table-column>
				<el-table-column prop="" :label="$t('i18nn_93c9ea4a0e52c641')"><template slot-scope="scope2">
						<div v-if="scope2.row.packageVo">
							<div>{{ scope2.row.packageVo.height }}</div>
						</div>
					</template></el-table-column>
				<el-table-column prop="" :label="$t('i18nn_8004917a89ed807b')"><template slot-scope="scope2">
						<div v-if="scope2.row.packageVo">
							<div>{{ scope2.row.packageVo.weight }}</div>
						</div>
					</template></el-table-column>
				<el-table-column prop="" :label="$t('i18nn_3775920d44088b96')"><template slot-scope="scope2">
						<div v-if="scope2.row.packageVo">
							<div>{{ scope2.row.packageVo.packageCount }}</div>
						</div>
					</template></el-table-column>
				
				<el-table-column prop="">
					<template :slot="'header'">
						<div>
							<span>{{$t('i18nn_7b16a04efec1728b')}}</span>(USD)
						</div>
					</template>
					<template slot-scope="scope2">
						<div v-if="scope2.row.packageVo">
							<div>{{ scope2.row.packageVo.insurance_value }}</div>
						</div>
				</template></el-table-column>
				
				<el-table-column prop="relationNo" :label="$t('i18nn_590686fdff40723b')"></el-table-column>
				
				<el-table-column prop="">
					<template :slot="'header'">
						<div>
							label<span>{{$t('i18nn_aa7237f43c313974')}}</span>
						</div>
					</template>
					<template slot-scope="scope">
						<div v-if="scope.row.others">{{ scope.row.others.remark }}</div>
					</template>
				</el-table-column>
				

				<!-- <el-table-column prop="remark" :label="$t('Storage.tableColumn.remark')"></el-table-column> -->

			</el-table>
		</div>
		<!-- <div class="dialog-footer">
			<el-button type="primary" @click="submitExcelForm()">{{ $t('FormMsg.Save') }}</el-button>
		</div> -->
		<!-- </el-dialog> -->

		<!--  附件上传 -->
		<!-- <whFileUpload :openTime="FileUploadOpenTime" :fileId="''" :fileKey="FileUploadKey" :relationId="fileRelationId" @success="FileUploadSuccess"></whFileUpload> -->
	</div>
</template>
<script>
// import WSkuInfo from './WSkuProduct/WSkuInfo.vue';

// import InWarehouseDetList from './InWarehouseDetList.vue';
// import whFileUpload from '@/components/StorageCenter/components/whFileUpload.vue';
// import SelAgentUser from '@/components/Common/SelAgentUser.vue';
import uploadExcelBtn from '@/components/StorageCenter/components/uploadExcelBtn.vue';

export default {
	// name: 'BuyerOrder',
	//meta信息seo用
	// metaInfo: {
	//   title: '互易天下-厂家共享平台-买家中心-收货账单', // set a title
	//   meta: [{ // set meta
	//     name: '互易天下-买家中心-收货账单',
	//     content: '互易天下-厂家共享平台-买家中心-收货账单'
	//   }],
	//   // link: [{                 // set link
	//   //   rel: 'asstes',
	//   //   href: 'https://assets-cdn.github.com/'
	//   // }]
	// },
	// props: ['isDialog'],
	props: {
	  excelDataDto: {
	    default: function() {
	      return [];
	    },
	    type: Array
	  }
	},
	components: {
		// WSkuInfo,
		// InWarehouseDetList,
		// whFileUpload,
		// SelAgentUser
		uploadExcelBtn
	},
	data() {
		return {
			
			//导入excel
			// dialogUploadVisible: false,
			loadingExcel: false,
			// fileExcel: '',
			excelData: [],
			// excelFileName: '',
			
			openTimeUpExc:"",

			//图片放大
			// dialogImgVisible: false,
			// imgUrlBigShow: '',

			loading: false,

			loading_det: false

		};
	},
	watch: {
	  excelDataDto: function(newVal, oldVal) {
	    console.log('excelDataDto');
	    this.excelData = this.excelDataDto;
	  }
	},
	//创建时
	created() {
		// this.getPageData();
		this.initData();
	},
	//编译挂载前
	mounted() {
		// this.getDicData();
		// this.excelData = this.excelDataDto;
		// console.log("dropShippingImport",this.excelDataDto);
		
	},
	methods: {
		

		initData() {
			this.openExcelDialog();
		},
		
		//删除数据
		delAction(event, row,index){
			event.stopPropagation();
			this.excelData.splice(index, 1);
		},

		// clearFile(){
		// 	this.fileExcel = null;
		// 	this.excelFileName = '';
		// 	// this.excelFileName = "";
		// 	this.$nextTick(() => {
		// 		try {
		// 			this.$refs.file.value = '';
		// 		} catch (e) {
		// 			console.log(e);
		// 			//TODO handle the exception
		// 		}
		// 	});
		// },
		//打开导入弹窗
		openExcelDialog() {
			// this.dialogUploadVisible = true;
			
			this.openTimeUpExc = new Date().getTime();
			this.excelData = [];
			// this.clearFile();
		},
		
		//上传数据回调
		uploadSuccess(data){
			// this.form.transferOrders = data.rows;
			// this.$forceUpdate();
			
			// this.excelData = data;
									// this.clearFile();
									// this.$emit('parsingSuccess', this.excelData);
			this.excelData = data;
			
			let formData = [];
			try {
				formData = JSON.parse(JSON.stringify(this.excelData));
			} catch (e) {
				this.$alert(this.$t('i18nn_7e1de493fb204260'), this.$t('tips.tipsTitle'), {
					type: 'warning'
				});
				return;
			}
			
			// formData.map(item => {
			// 	// item.id = null;
			// 	item.expressSkuList.map(item2 => {
			// 		// item2.id = null;
			// 		item2.mySkuDto = null;
			// 	});
			// });
			// this.clearFile();
			this.$emit('parsingSuccess', formData);
		},
		
		// fileChange(e) {
		// 	// this.loading = true;
		// 	console.log(e);
		// 	this.fileExcel = null;
		// 	this.excelFileName = '';
		// 	console.log(this.$t('i18nn_f156a9536049f461'), this.$refs.file);
		// 	this.excelData = [];
		// 	if (!this.$refs.file || !this.$refs.file.files) {
		// 		this.$alert(this.$t('module.upload.Unknown_file'), this.$t('tips.tipsTitle'), {
		// 			// //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
		// 		});
		// 		return;
		// 	}
		// 	if (this.$refs.file.files[0]) {
		// 		// if (this.fileAccept.indexOf(this.$refs.file.files[0].type) > -1) {
		// 		if (this.$refs.file.files[0].size < 1024 * 1024 * 10) {
		// 			this.fileExcel = this.$refs.file.files[0];
		// 			this.excelFileName = this.fileExcel.name;
		// 			// console.log(this.$t('i18nn_f156a9536049f461'),this.$refs.file);
		// 			// this.uploadExcel();
		// 		} else {
		// 			this.$alert(this.$t('module.upload.uploaded_over') + '10M', this.$t('tips.tipsTitle'), {});
		// 		}
		// 	} else {
		// 		// this.$alert(this.$t('i18nn_c8e99e36cefd743a'), this.$t('tips.tipsTitle'), {
		// 		// });
		// 		console.log(this.$t('i18nn_c8e99e36cefd743a'), this.$refs.file);
		// 	}
		// },
		// openExcel() {
		// 	try {
		// 		this.$refs.file.value = '';
		// 	} catch (e) {
		// 		console.log(e);
		// 		//TODO handle the exception
		// 	}
		// 	// this.$refs.file.value = '';
		// 	// this.$refs.file.dispatchEvent(new MouseEvent('click'));
		// 	var comment = this.$refs.file;
		// 	if (document.all) {
		// 		// For IE
		// 		comment.click();
		// 	} else if (document.createEvent) {
		// 		// 在ff中要为a标签添加click事件，并且侦听该事件
		// 		var ev = document.createEvent('MouseEvents');
		// 		ev.initEvent('click', false, true);
		// 		comment.dispatchEvent(ev);
		// 	} else {
		// 		// this.$alert('打开上传文件有误，请联系客服', this.$t('tips.tipsTitle'), {
		// 		this.$alert(this.$t('module.upload.open_error_img'), this.$t('tips.tipsTitle'), {
		// 			// //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
		// 		});
		// 	}
		// },
		//导入excel
		// uploadExcel() {
		// 	if (!this.fileExcel) {
		// 		this.$alert(this.$t('module.upload.Please_sel_file'), this.$t('tips.tipsTitle'), {});
		// 		return;
		// 	}

		// 	this.loadingExcel = true;

		// 	let file = this.fileExcel;
		// 	var formdata = new FormData();
		// 	formdata.append('file', file);

		// 	this.$http
		// 		.post(this.$urlConfig.WhFuerthertryReadExcel, formdata, {
		// 			headers: {
		// 				'Content-Type': 'multipart/form-data'
		// 			}
		// 		})
		// 		.then(({ data }) => {
		// 			console.log(this.$t('i18nn_bc868e024b80d2e3'));
		// 			console.log(data);
		// 			this.loadingExcel = false;
		// 			if (200 == data.code && data.rows) {
		// 				this.excelData = data.rows;

		// 				let formData = [];
		// 				try {
		// 					formData = JSON.parse(JSON.stringify(this.excelData));
		// 				} catch (e) {
		// 					this.$alert(this.$t('i18nn_7e1de493fb204260'), this.$t('tips.tipsTitle'), {
		// 						type: 'warning'
		// 					});
		// 					return;
		// 				}

		// 				// formData.map(item => {
		// 				// 	// item.id = null;
		// 				// 	item.expressSkuList.map(item2 => {
		// 				// 		// item2.id = null;
		// 				// 		item2.mySkuDto = null;
		// 				// 	});
		// 				// });
		// 				this.clearFile();
		// 				this.$emit('parsingSuccess', formData);
		// 			} else {
		// 				this.$alert(data.msg ? data.msg : this.$t('tips.submitError'), this.$t('tips.tipsTitle'), {
		// 					type: 'warning'
		// 					//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
		// 				});
		// 			}
		// 		})
		// 		.catch(error => {
		// 			console.log(error);
		// 			console.log(this.$t('tips.submitError'));
		// 			this.loadingExcel = false;
		// 			this.$alert(this.$t('tips.submitRequestError'), this.$t('tips.tipsTitle'), {
		// 				type: 'warning'
		// 				//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
		// 			});
		// 		});
		// },
		
		//查询数据字典
		// getDicData() {
		// 	// let _this = this;
		// 	// console.log(keyword);
		// 	// this.loading_load = true;
		// 	// this.$http
		// 	//   .put(this.$urlConfig.HyDicQueryList, ['wh_packing_type', 'wh_size_unit'])
		// 	//   .then(({ data }) => {
		// 	//     console.log('查询数据字典，请求成功');
		// 	//     console.log(data);
		// 	//     if (200 == data.code && data.data) {
		// 	//       // this.selectOption.wh_weight_unit = data.data['wh_weight_unit'];
		// 	//       // this.selectOption.wh_vol_unit = data.data['wh_vol_unit'];
		// 	//       // this.selectOption.wh_goods_fee_type = data.data['wh_goods_fee_type'];
		// 	//       this.selectOption.wh_size_unit = data.data['wh_size_unit'];
		// 	//       this.selectOption.wh_packing_type = data.data['wh_packing_type'];
		// 	//     } else {
		// 	//       if (!data.msg) {
		// 	//         data.msg = this.$t("tips.submitError");
		// 	//       }
		// 	//       this.$message.warning(data.msg);
		// 	//     }
		// 	//   })
		// 	//   .catch(error => {
		// 	//     console.log(error);
		// 	//     console.log('查询数据字典接口，请求失败');
		// 	//     this.$message.error(this.$t("tips.submitRequestError"));
		// 	//   });
		// }
	}
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
/deep/ .el-input-number,
.el-select {
	// width: 100px;
	.el-input__inner {
		text-align: left;
	}
}
.form_msg {
	color: #e6a23c;
}
/deep/ .el-table__expanded-cell {
	background-color: #f5f5f5;
	padding: 5px 50px;
	// &:hover {
	//   background-color: #f5f5f5;
	// }
	.el-table {
		// tr,th {
		//   background-color: #f5f5f5;
		// }
		th {
			background-color: #d2e2f9;
		}
	}
}

/deep/ .el-table__expand-icon {
	width: 40px;
	height: 40px;
	line-height: 40px;
	font-size: 20px;
	font-weight: bold;
	// padding: 0px;
	// margin-left: -20px;
	// margin-top: -20px;
	.el-icon {
		margin-left: -10px;
		margin-top: -10px;
	}
}
</style>
