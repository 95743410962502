<template>
	<div style="display: flex;">
		<div>
			<!-- <el-form-item :label="'粘贴长地址'" prop=""> -->
			<!-- {{type}},{{placeholder}} -->
			<el-input type="textarea" :autosize="{ minRows: row}" :placeholder="placeholder" v-model="allAddress"
				maxlength="500" show-word-limit style="width: 800px;" @input="matchAddress()"></el-input>
			<!-- </el-form-item> -->
		</div>

		<div>
			<el-button size="small" type="text" icon="el-icon-help" @click="showExample()">示例</el-button>
			<!-- <br />
			<el-button size="small" type="warning" icon="el-icon-attract" @click="matchAddress()">
				智能填充
			</el-button> -->
		</div>


	</div>
</template>

<script>
	// import { downloadMergePDF } from '@/utils/pdfLibUtils.js';
	import {
		clearObjectVal
	} from '@/utils/common.js';
	export default {
		props: {

			openTime: {

			},
			row: {
				default: function() {
					return 2;
				},
				type: Number
			},
			type: { //匹配模式

			}

		},
		components: {
			// HyElWhUpLoad
		},
		data() {
			return {
				allAddress: "",
				placeholder: "粘贴长地址,自动识别填充",
				form: {
					"recipient": "",
					"phone": "",
					"street1": "",
					// "street2": "",
					// "streetLines": [],
					"city": "",
					"stateOrProvinceCode": "",
					"postalCode": "",
					"countryCode": ""
				}

			};
		},
		watch: {
			openTime: function(newVal, oldVal) {
				console.log('openTime');
				this.initData();
			}
		},
		//创建时
		created() {
			this.initData();
		},
		//编译挂载前
		mounted() {

		},
		methods: {
			initData() {
				this.allAddress = "";
				this.form = clearObjectVal(this.form);
				if (1 == this.type) {
					this.placeholder = "(街道地址,城市,州(二字码),邮编,国家)粘贴长地址,自动识别填充";
				} else if (2 == this.type) {
					this.placeholder = "(收件人,电话,街道地址,城市,州(二字码),邮编,国家)粘贴长地址,自动识别填充";
				} else {
					this.placeholder = "粘贴长地址,自动识别填充";
				}
				// console.log('longaddr initData',this.placeholder);
			},

			showExample() {
				if (1 == this.type) {
					this.$alert(`
						<div style="color:#409EFF;">街道地址,城市,州(二字码),邮编,国家</div>
						<span style="color:red;">示例一:</span><br />1033 N Todd Ave, Azusa, CA 91702美国<br />
						<span style="color:red;">示例二:</span><br />1033 N Todd Ave,Azusa,CA,91702,US
					`, '提示', {
						dangerouslyUseHTMLString: true
					});
				} else if (2 == this.type) {
					this.$alert(`
					<div style="color:#409EFF;">收件人,电话,街道地址,城市,州(二字码),邮编,国家</div>
					<span style="color:red;">示例一:</span><br />Fiona,(+1)626-989-9999,1033 N Todd Ave,Azusa, CA,91702,US<br />
					<span style="color:red;">示例二:</span><br />
						Fiona<br />
						(+1)626-989-9999<br />
						1033 N Todd Ave<br />
						Azusa<br /> 
						CA<br />
						91702<br />
						US<br />
					`, '提示', {
						dangerouslyUseHTMLString: true
					});
				} else {
					this.$alert(`
						1033 N Todd Ave, Azusa, CA 91702美国<br />
						1033 N Todd Ave,Azusa,CA,91702,US
					`, '提示', {
						dangerouslyUseHTMLString: true
					});
				}

			},

			//多个尝试匹配
			// tryAddrCodeByList(text,list) {
			// 	let stateOrProvinceCode_REG = /[A-Z]{2}/g;
			// 	let postalCode_REG = /[0-9]{5}/g;
			// 	// 二字码尝试匹配
			// 	list.map(item=>{
			// 		if(text.test(stateOrProvinceCode_REG)){
			// 			let adr_prov = text.match(stateOrProvinceCode_REG);
			// 			if (adr_prov && adr_prov[0]) {
			// 				this.form.stateOrProvinceCode = adr_prov[0].trim();
			// 			}
			// 		}
			// 	})
			// 	// let adr_prov = text.match(/[A-Z]{2}/g);
			// 	// if (adr_prov && adr_prov[0]) {
			// 	// 	this.form.stateOrProvinceCode = adr_prov[0].trim();
			// 	// }
			// 	//邮编尝试匹配
			// 	list.map(item=>{
			// 		if(text.test(postalCode_REG)){
			// 			let adr_postalCode = text.match(postalCode_REG);
			// 			if (adr_postalCode && adr_postalCode[0]) {
			// 				this.form.postalCode = adr_postalCode[0].trim();
			// 			}
			// 		}
			// 	})
			// 	// let adr_postalCode = text.match(/[0-9]{5}/g);
			// 	// if (adr_postalCode && adr_postalCode[0]) {
			// 	// 	this.form.postalCode = adr_postalCode[0].trim();
			// 	// }
			// },

			//尝试匹配
			// tryAddrCode(text) {
			// 	// 二字码尝试匹配
			// 	let adr_prov = text.match(/[A-Z]{2}/g);
			// 	if (adr_prov && adr_prov[0]) {
			// 		this.form.stateOrProvinceCode = adr_prov[0].trim();
			// 	}
			// 	//邮编尝试匹配
			// 	let adr_postalCode = text.match(/[0-9]{5}/g);
			// 	if (adr_postalCode && adr_postalCode[0]) {
			// 		this.form.postalCode = adr_postalCode[0].trim();
			// 	}
			// },

			//遍历，尝试匹配并且清空
			// tryAddrCodeClear(text, index) {
			// 	//国家尝试匹配
			// 	let adr_country = text.match(/[A-Z]{2}/g);
			// 	if (adr_country && adr_country[0]) {
			// 		this.form.stateOrProvinceCode = adr_prov[0].trim();
			// 		return text.replace(this.form.stateOrProvinceCode, '').trim();
			// 	}
			// 	// 二字码尝试匹配
			// 	let adr_prov = text.match(/[A-Z]{2}/g);
			// 	if (adr_prov && adr_prov[0]) {
			// 		this.form.stateOrProvinceCode = adr_prov[0].trim();
			// 		return text.replace(this.form.stateOrProvinceCode, '').trim();
			// 	}
			// 	//邮编尝试匹配
			// 	let adr_postalCode = text.match(/[0-9]{5}/g);
			// 	if (adr_postalCode && adr_postalCode[0]) {
			// 		this.form.postalCode = adr_postalCode[0].trim();
			// 		return text.replace(this.form.postalCode, '').trim();
			// 	}
			// },

			matchAddress() {
				let allAddress = this.allAddress;
				console.log('allAddress', this.allAddress);
				this.form = clearObjectVal(this.form);
				//分割-替换
				allAddress = allAddress.replace(/[\r\n]/g, ',');
				// if (1 == this.type) {
				// 	// 1033 N Todd Ave, Azusa, CA 91702美国
				// 	// 1033 N Todd Ave,Azusa,CA,91702,US
				// 	//分割
				// 	let addressList = allAddress.split(',');
				// 	// this.form = {
				// 	// 	"street1": "",
				// 	// 	"street2": "",
				// 	// 	"city": "",
				// 	// 	"stateOrProvinceCode": "",
				// 	// 	"postalCode": "",
				// 	// 	"countryCode": "US"
				// 	// }
				// 	if (addressList[0]) {
				// 		this.form.street1 = addressList[0].trim();
				// 	}
				// 	if (addressList[1]) {
				// 		this.form.city = addressList[1].trim();
				// 	}
				// 	if (addressList[2]) {
				// 		this.form.stateOrProvinceCode = addressList[2].trim();
				// 		this.tryAddrCode(addressList[2]);
				// 	}
				// 	if (addressList[3]) {
				// 		this.form.postalCode = addressList[3].trim();
				// 		this.tryAddrCode(addressList[3]);
				// 	}
				// } else if (2 == this.type) {
					console.log('allAddress2', allAddress);
					//分割
					let addressList = allAddress.split(',');

					//从后往前匹配
					for (let i = addressList.length; i >= 0; i--) {
						// let text = addressList[i];
						//匹配国家清空
						if (addressList[i] && !this.form.countryCode) {
							// this.form.countryCode = "US";
							// this.allAddress.replace(/(US|美国|United States)/g,'').trim();
							// break;
							let adr_country = addressList[i].match(/(US|美国|United States)/g);
							if (adr_country && adr_country[0] && !this.form.countryCode) {
								this.form.countryCode = "US";
								addressList[i] = addressList[i].replace(/(US|美国|United States)/g, '').trim();
								// console.log('countryCode', i, addressList[i]);
								// ' CA 91702 US'.replace(/(US|美国|United States)/g, '').trim()
								// ' CA 91702 US'.match(/(US|美国|United States)/g)
								// break;
							}
							// console.log('countryCode2', i, addressList[i]);
						}
						//邮编尝试匹配
						if (addressList[i] && !this.form.postalCode) {
							let adr_postalCode = addressList[i].match(/[0-9]{5}/g);
							if (adr_postalCode && adr_postalCode[0] && !this.form.postalCode) {
								this.form.postalCode = adr_postalCode[0].trim();
								addressList[i] = addressList[i].replace(this.form.postalCode, '').trim();
								// break;
							}
						}
						// 二字码尝试匹配
						if (addressList[i] && !this.form.stateOrProvinceCode) {
							let adr_prov = addressList[i].match(/[A-Z]{2}/g);
							if (adr_prov && adr_prov[0] && !this.form.stateOrProvinceCode) {
								this.form.stateOrProvinceCode = adr_prov[0].trim();
								addressList[i] = addressList[i].replace(this.form.stateOrProvinceCode, '').trim();
								// break;
							}
						}
						//城市
						if (addressList[i] && !this.form.city) {
							this.form.city = addressList[i].trim();
							addressList[i] = addressList[i].replace(this.form.city, '').trim();
							// console.log('city', i, addressList[i]);
							// break;
						}
						//街道
						if (addressList[i] && !this.form.street1) {
							this.form.street1 = addressList[i].trim();
							addressList[i] = addressList[i].replace(this.form.street1, '').trim();
							// break;
						}
						//电话
						if (addressList[i] && !this.form.phone) {
							this.form.phone = addressList[i].trim();
							addressList[i] = addressList[i].replace(this.form.phone, '').trim();
							// break;
						}
						//收件人
						if (addressList[i] && !this.form.recipient) {
							this.form.recipient = addressList[i].trim();
							addressList[i] = addressList[i].replace(this.form.recipient, '').trim();
							// break;
						}
					}


					// if (addressList[0]) {
					// 	this.form.recipient = addressList[0].trim();
					// }
					// if (addressList[1]) {
					// 	this.form.phone = addressList[1].trim();
					// }
					// if (addressList[2]) {
					// 	this.form.street1 = addressList[2].trim();
					// }
					// if (addressList[3]) {
					// 	this.form.city = addressList[3].trim();
					// }
					// if (addressList[4]) {
					// 	this.form.stateOrProvinceCode = addressList[4].trim();
					// 	this.tryAddrCode(addressList[4]);
					// }
					// if (addressList[5]) {
					// 	this.form.postalCode = addressList[5].trim();
					// 	this.tryAddrCode(addressList[5]);
					// }
					// if (addressList[6]) {
					// 	this.form.postalCode = addressList[6].trim();
					// 	this.tryAddrCode(addressList[6]);
					// }
				// } else {
				// 	//分割
				// 	let addressList = allAddress.split(',');
				// 	if (addressList[0]) {
				// 		this.form.street1 = addressList[0].trim();
				// 	}
				// 	if (addressList[1]) {
				// 		this.form.city = addressList[1].trim();
				// 	}
				// 	if (addressList[2]) {
				// 		this.form.stateOrProvinceCode = addressList[2].trim();
				// 		this.tryAddrCode(addressList[2]);
				// 	}
				// 	if (addressList[3]) {
				// 		this.form.postalCode = addressList[3].trim();
				// 		this.tryAddrCode(addressList[3]);
				// 	}
				// }

				this.form.countryCode = "US";
				this.$emit('parse', this.form)
			},

		},
	}
</script>

<style>
</style>