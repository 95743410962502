<template>
  <div v-loading="loadingExcel">
  	<input
  		style="display: none;"
  		type="file"
  		id=""
  		ref="file"
  		@change="fileChange($event)"
  		:accept="'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'"
  />	
  	<!-- <el-button type="success" size="small" icon="el-icon-plus" @click="openExcel()">{{ $t('module.upload.Select_Excel') }}</el-button> -->
		<el-button type="success" size="small" icon="el-icon-upload2" @click="openExcel()">
			{{btnText}}
		</el-button>
  	<span style="display: inline-block; width: 200px;">
  		{{ excelFileName }}
  		<!-- {{fileExcel}} -->
  	</span>
  	<!-- <el-button type="primary" size="small" icon="el-icon-upload" @click="uploadExcel()">{{ $t('module.upload.Upload_preview') }}</el-button> -->
  	<span style="padding-left: 100px;">
  		<el-link 
  			:href="templateUrl+'?random=' + Math.random()"
  			target="_blank"
  			type="primary"
  			icon="el-icon-download"
  		>
  			<span>{{ $t('module.upload.Download_template') }}</span>
				<span>{{version}}</span>&nbsp;
				<strong class="red" v-if="isUpdate">{{$t('i18nn_a87302a8cd63eae8')}}</strong>
  		</el-link>
  	</span>
  </div>
</template>
<script>
// import HyElWhUpLoad from '@/components/Common/HyElWhUpLoad.vue';
export default {
  props: {
		readHttp: {
      default: function() {
        return '';
      },
      type: String
    },
    openTime: {
      // default: function() {
      //   return '';
      // },
      // type: String
    },
    templateUrl: {
      default: function() {
        return '';
      },
      type: String
    },
		btnText: {
      default: function() {
        return this.$t('module.upload.Select_Upload_Excel');
      },
      type: String
    },
		version: {
		  default: function() {
		    return 'V2';
		  },
		  type: String
		},
		isUpdate: {
		  default: function() {
		    return false;
		  },
		  type: Boolean
		},
		readParm: {
		  default: function() {
		    return {};
		  },
		  type: Object
		},
  },
  components: {
    // HyElWhUpLoad
  },
  data() {
    return {
      fileExcel:null,
			excelFileName:"",
			loadingExcel:false,
    };
  },
  watch: {
    openTime: function(newVal, oldVal) {
      this.initData();
    }
  },
  //创建时
  created() {
  },
  //编译挂载前
  mounted() {
    // this.initData();
  },
  methods: {
    initData() {
      this.clearFile();
    },
    fileChange(e) {
    	// this.loading = true;
    	console.log(e);
    
    	console.log(this.$t('i18nn_f156a9536049f461'), this.$refs.file);
    	// this.excelData = [];
    	// this.form.transferOrders = [];
    	// (this.fileExcel = null), (this.excelFileName = '');
    	if (!this.$refs.file || !this.$refs.file.files) {
    		this.$alert(this.$t('module.upload.Unknown_file'), this.$t('tips.tipsTitle'), {
    			// //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
    		});
    		return;
    	}
    	if (this.$refs.file.files[0]) {
    		// if (this.fileAccept.indexOf(this.$refs.file.files[0].type) > -1) {
    		if (this.$refs.file.files[0].size < 1024 * 1024 * 10) {
    			this.fileExcel = this.$refs.file.files[0];
    			this.excelFileName = this.fileExcel.name;
    			// console.log(this.$t('i18nn_f156a9536049f461'),this.$refs.file);
    			this.uploadExcel();
    		} else {
    			this.$alert(this.$t('module.upload.uploaded_over') + '10M', this.$t('tips.tipsTitle'), {});
    		}
    		// } else {
    		//   this.$alert(this.$t(this.$t('i18nn_4dadf13ebce2167d')), this.$t('tips.tipsTitle'), {
    		//   });
    		// }
    	} else {
    		// this.$alert(this.$t('i18nn_c8e99e36cefd743a'), this.$t('tips.tipsTitle'), {
    		// });
    		console.log(this.$t('i18nn_c8e99e36cefd743a'), this.$refs.file);
    	}
    },
    clearFile() {
    	this.fileExcel = null;
    	this.excelFileName = '';
    	// this.excelFileName = "";
    	this.$nextTick(() => {
    		try {
    			this.$refs.file.value = '';
    		} catch (e) {
    			console.log(e);
    			//TODO handle the exception
    		}
    	});
    },
    openExcel() {
    	try {
    		this.$refs.file.value = '';
    	} catch (e) {
    		console.log(e);
    		//TODO handle the exception
    	}
    	// this.$refs.file.value = '';
    	// this.$refs.file.dispatchEvent(new MouseEvent('click'));
    	var comment = this.$refs.file;
    	if (document.all) {
    		// For IE
    		comment.click();
    	} else if (document.createEvent) {
    		// 在ff中要为a标签添加click事件，并且侦听该事件
    		var ev = document.createEvent('MouseEvents');
    		ev.initEvent('click', false, true);
    		comment.dispatchEvent(ev);
    	} else {
    		// this.$alert('打开上传文件有误，请联系客服', this.$t('tips.tipsTitle'), {
    		this.$alert(this.$t('module.upload.open_error_img'), this.$t('tips.tipsTitle'), {
    			// //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
    		});
    	}
    },
    //导入excel
    uploadExcel() {
    	if (!this.fileExcel) {
    		this.$alert(this.$t('module.upload.Please_sel_file'), this.$t('tips.tipsTitle'), {});
    		return;
    	}
    
    	this.loadingExcel = true;
    
    	let file = this.fileExcel;
    	var formdata = new FormData();
    	formdata.append('file', file);
			if(this.readParm){
				// this.readParm.map((key,val)=>{
				// 	formdata.append(key, val);
				// });
				for (let key in this.readParm) {
				 if (this.readParm.hasOwnProperty(key)) {
				  formdata.append(key, this.readParm[key]);
				 }
				}
			}
    	this.$http
    		.post(this.readHttp, formdata, {
    			headers: {
    				'Content-Type': 'multipart/form-data'
    			}
    		})
    		.then(({ data }) => {
    			console.log(this.$t('i18nn_bc868e024b80d2e3'));
    			console.log(data);
    			this.loadingExcel = false;
    			if (200 == data.code && data.rows) {
    				// this.excelData = data.rows;
    
    				// this.form.transferOrders = data.rows;
						
						this.$emit("uploadSuccess",data.rows);
    
    				this.$forceUpdate();
    				// this.clearFile();
						// this.$message.success(this.$t('i18nn_3144aad544eb9ac4'));
    			} else {
    				this.$alert(data.msg ? data.msg : this.$t('tips.submitError'), this.$t('tips.tipsTitle'), {
    					type: 'warning'
    					//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
    				});
						this.$emit("uploadSuccess",[]);
    			}
    		})
    		.catch(error => {
    			console.log(error);
    			console.log(this.$t('tips.submitError'));
    			this.loadingExcel = false;
    			this.$alert(this.$t('tips.submitRequestError'), this.$t('tips.tipsTitle'), {
    				type: 'warning'
    				//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
    			});
					this.$emit("uploadSuccess",[]);
    		});
    },
  }
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less"></style>
